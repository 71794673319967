<template>
  <v-container fluid>
    <PageTitle title="Account" />
    <v-card>
      <v-tabs v-model="selectedTab">
        <v-tab>Informasi Pemilik Bisnis</v-tab>
        <v-tab>Informasi Bisnis</v-tab>
        <v-tab>Informasi Bank</v-tab>
      </v-tabs>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-tabs-items v-model="selectedTab">
              <v-tab-item>
                <v-card-title class="px-0">Informasi Pemilik Bisnis</v-card-title>
                <v-form>
                  <v-text-field
                    label="Nama Pemilik Bisnis"
                    outlined
                    dense
                  />
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                  />
                  <v-text-field
                    label="Nomor Telepon"
                    outlined
                    dense
                  />
                  <v-btn elevation="2" color="zipay">
                    Save changes
                  </v-btn>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <v-card-title class="px-0">Informasi Bisnis</v-card-title>
                <v-form>
                  <v-text-field
                    label="Nama Bisnis"
                    outlined
                    dense
                  />
                  <v-text-field
                    label="Nomor Telepon"
                    outlined
                    dense
                    hide-details="auto"
                  />
                  <v-row>
                    <v-col>
                      <v-checkbox
                        label="Offline"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        label="Online"
                      />
                    </v-col>
                  </v-row>
                  <v-text-field
                    label="Alamat Bisnis"
                    outlined
                    dense
                  />
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Kota"
                        outlined
                        dense
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Kelurahan"
                        outlined
                        dense
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Kecamatan"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Kode Pos"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-text-field
                    label="Nomor Telepon"
                    outlined
                    dense
                  />
                  <v-btn elevation="2" color="zipay">
                    Save changes
                  </v-btn>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <v-card-title class="px-0">Informasi Bank</v-card-title>
                <v-form>
                  <v-text-field
                    label="Nama Bank"
                    outlined
                    dense
                  />
                  <v-text-field
                    label="Cabang"
                    outlined
                    dense
                  />
                  <v-text-field
                    label="Nomor Rekening"
                    outlined
                    dense
                  />
                  <v-text-field
                    label="Nama Rekening"
                    outlined
                    dense
                  />
                  <v-btn elevation="2" color="zipay">
                    Save changes
                  </v-btn>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from "@/components/molecules/PageTitle.vue";

export default {
  components: {
    PageTitle
  },
  data() {
    return {
      selectedTab: null
    }
  }
};
</script>
